<div class="widget-header">
    <div class="widget-header_left">
        <div class="widget-title">{{widgetTitle}}</div>
        <div class="widget-subtitle">Last Updated On {{lastUpdatedDate}}</div>
    </div>
    <div class="widget-header_right">
        <div *ngIf="isTATByTitle" class="flex-v-center-h-end flex-gap-2">
            <p-dropdown [options]="dropdown3Data"
                [(ngModel)]="dropdown3"
                (onChange)="dropdown3Changed($event.value)">
            </p-dropdown>            
            <p-dropdown [options]="dropdown4Data"
                [(ngModel)]="dropdown4"
                (onChange)="dropdown4Changed($event.value)">
            </p-dropdown>
        </div>
        <div *ngIf="isSwitchDropdown" class="flex-v-center-h-end flex-gap-2">
            <p-dropdown [options]="dropdown1Data"
                [(ngModel)]="dropdown1"
                (onChange)="dropdown1Changed($event.value)">
            </p-dropdown>            
            <p-dropdown [options]="dropdown2Data"
                [(ngModel)]="dropdown2"
                (onChange)="dropdown2Changed($event.value)">
            </p-dropdown>
        </div>
        <div>
            <span class="p-input-icon-left" *ngIf="widgetTitle === 'Budget Details'">
                <i class="pi pi-search"></i>
                <input placeholder="Search...." [(ngModel)]="quickSearchValue" (blur)="onBlurEmitValue()" pInputText
                    class="border-radius-right" />
            </span>
            <button class="btn btn-sm btn-outline-secondary export" 
                (click)="emitExportClicked()"
                type="button" >
                <i class="bi bi-file-earmark-excel me-2"></i> Export
            </button>
        </div>
    </div>
</div>