<div style="height: 400px;" class="d-flex">
    <div style="width: 35%; height: 100%;" *ngIf="id && data">
        <tg-pie-chart 
            [centerText1]="centerText1"
            [centerText2]="centerText2"
            [data]="data"
            [id]="id"
            [reportType]="reportType"
        ></tg-pie-chart>
    </div>
    <div class="flex-v-center">
        <p-table
            [responsive]="true"
            [value]="data">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 115px;">Ageing</th>
                    <th>No. Of Invoices</th>
                    <th>Total Invoice Amount</th>
                    <th style="min-width: 80px;">Mix</th>
                    <th>Indicator</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>{{rowData.name ?? '-'}}</td>
                    <td>{{(rowData.y ?? 0)}}</td>
                    <td>{{(rowData.invoiceAmount ?? 0) | currency: 'INR'}}</td>
                    <td>{{rowData.mix ?? 0 }} %</td>
                    <td class="pointer" (click)="redirectToInovoice(rowData.name)"><div class="indicator" [style.background-color]="rowData.color"></div></td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr>
                    <td>Grand Total</td>
                    <td>{{ getTotalInoices()}}</td>
                    <td>{{ (getTotalInoiceAmount() ?? 0) | currency : 'INR' }}</td>
                    <td></td>
                    <td></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>