// excel.service.ts

import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  exportToExcel(data: any[], filename: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename + '.xlsx');
  }

  exportToCSV(data: any[], filename: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Check if the !ref property exists
    const range = ws['!ref'] ? XLSX.utils.decode_range(ws['!ref']) : { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };

    // Add style to the first row (header row) to set background color to yellow
    const headerCellStyle = { fill: { patternType: 'solid', fgColor: "yellow" } };
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: 0 }; // first row
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!ws[cellRef]) {
        ws[cellRef] = {};
      }
      if (!ws[cellRef].s) {
        ws[cellRef].s = {};
      }
      Object.assign(ws[cellRef].s, headerCellStyle);
    }

    const csv: string = XLSX.utils.sheet_to_csv(ws);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary link element to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename + ".csv";

    // Trigger the download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(link.href);
  }





}
