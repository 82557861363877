<div class="dashboard-header">
  <h6 class="dashboard-header__heading">Hello {{viewModel?.employeeDetail?.firstName}}
    {{viewModel?.employeeDetail?.lastName}},<br />
  </h6>

  <div class="dashboard-header__actions">
    <div class="dashboard-header__filter">
      <div class="flex-gap-2">
        <p-dropdown id="financialYear" [options]="financialYearList" optionLabel="value" optionValue="value"
          [(ngModel)]="selectedYear" placeholder="Financial Year" (ngModelChange)="onFinancialYearChange()">
        </p-dropdown>
        <p-dropdown id="month" [options]="monthsList" optionLabel="value" optionValue="id"
          (ngModelChange)="onMonthChange($event)" [(ngModel)]="selectedMonth" placeholder="Month">
        </p-dropdown>
        <button type="button" class="dashboard-config" (click)="toggleMenu()"><i class="bi bi-columns-gap"></i></button>
        <div *ngIf="menuVisible" class="menu-container">
          <div class="menu" cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="header">
              <div class="spacer"><i (click)="onClose()" class="bi bi-x-lg pointer"></i></div>
              <div class="button-container">
                <button class="btn btn-sm btn-outline-secondary reset" [disabled]="!hasChanges" (click)="resetChanges()"
                  type="button"><i class="bi bi-arrow-clockwise"></i> Reset</button>
                <button class="tg-submit-btn" type="button" (click)="saveChanges()" [disabled]="!hasChanges">
                  <i class="fa fa-save me-2"></i>Save
                </button>
              </div>
            </div>
            <div *ngFor="let item of widgetBody" cdkDrag class="menu-item">
              <span><i class="bi bi-grip-vertical"></i> {{ item.name }}</span><p-inputSwitch [(ngModel)]="item.isActive"
                (ngModelChange)="onSwitchChange(item)"></p-inputSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-stats" *ngIf="statsData">
  <tg-statistics-widget [title]="vendorCountTitle" [count]="statsData.totalVendorCount"
    [additionalInfo]="vendorCountInfo" [icon]="iconVendorCount" [color]="'pink'"
    [insight]="statsData.totalVendorInsight">
  </tg-statistics-widget>

  <tg-statistics-widget [title]="invoiceCountTitle" [count]="statsData.totalInvoiceCount"
    [additionalInfo]="additionalInfo" [icon]="iconInvoiceCount" [color]="'green'"
    [insight]="statsData.totalInvoiceCountInsight">
  </tg-statistics-widget>

  <tg-statistics-widget [title]="taxableAmountTitle" [count]="statsData.totalTaxableAmount"
    [additionalInfo]="additionalInfo" [icon]="iconTaxableAmount" [currency]="true" [color]="'blue'"
    [insight]="statsData.totalTaxableAmountInsight">
  </tg-statistics-widget>

  <tg-statistics-widget [title]="invoiceAmountTitle" [count]="statsData.totalInvoiceAmount"
    [additionalInfo]="additionalInfo" [icon]="iconInvoiceAmount" [currency]="true" [color]="'yellow'"
    [insight]="statsData.totalInvoiceAmountInsight">
  </tg-statistics-widget>

  <tg-statistics-widget [title]="taxAmountTitle" [count]="statsData.totalTaxAmount" [additionalInfo]="additionalInfo"
    [icon]="iconTaxAmount" [currency]="true" [color]="'sky'" [insight]="statsData.totalTaxAmountInsight">
  </tg-statistics-widget>
</div>

<div class="dashboard">
  <ng-container *ngFor="let widget of sortedWidgets">
    <div *ngIf="widget.isActive" class="widget half-width">
      <tg-widget-header *ngIf="widgetData" 
        [widgetTitle]="widget.name" 
        [lastUpdatedDate]="getWidgetData(widget.widgetId)?.data?.lastUpdatedAt"
        [isSwitchDropdown]="getWidgetData(widget.widgetId)?.isSwitchDropdown"
        [dropdown1Data]= "getWidgetData(widget.widgetId)?.dropdown1Data"
        [dropdown2Data]= "getWidgetData(widget.widgetId)?.dropdown2Data"
        [dropdown3Data]= "getWidgetData(widget.widgetId)?.dropdown3Data"
        [dropdown4Data]= "getWidgetData(widget.widgetId)?.dropdown4Data"
        [widgetId]="widget.widgetId"
        [isTATByTitle]="getWidgetData(widget.widgetId)?.isTATByTitle"
        (dropdown1ChangedEmit)="getDropdown2Data($event)"
        (dropdown2ChangedEmit)="getDataAfterDropdown2Change($event)"
        (dropdown3ChangedEmit)="getDropdown4Data($event)"
        (dropdown4ChangedEmit)="getDataAfterDropdown4Change($event)"
        (quickSearchBlur)="getSearchValue($event)"
        (exportClickEmit)="exportExcel($event, widget.widgetId, widgetData)"
        >
      </tg-widget-header>

      <ng-container [ngSwitch]="getWidgetComponent(widget)" *ngIf="widgetData">
        <tg-document-detail-count-widget *ngSwitchCase="'tg-document-detail-count-widget'"
          [documentName]="getWidgetData(widget.widgetId)?.name"
          [draftCount]="getWidgetData(widget.widgetId)?.data?.data.draftCount"
          [approvedCount]="getWidgetData(widget.widgetId)?.data?.data.approvedCount"
          [rejectCount]="getWidgetData(widget.widgetId)?.data?.data.rejectedCount"
          [submitCount]="getWidgetData(widget.widgetId)?.data?.data.submittedCount"
          [draftAmount]="getWidgetData(widget.widgetId)?.data?.data.draftAmount"
          [approvedAmount]="getWidgetData(widget.widgetId)?.data?.data.approvedAmount"
          [rejectAmount]="getWidgetData(widget.widgetId)?.data?.data.rejectedAmount"
          [submitAmount]="getWidgetData(widget.widgetId)?.data?.data.submittedAmount"
          [holdCount]="getWidgetData(widget.widgetId)?.data?.data.holdCount"
          [parkedCount]="getWidgetData(widget.widgetId)?.data?.data.parkedCount"
          [postedCount]="getWidgetData(widget.widgetId)?.data?.data.postedCount"
          [paidCount]="getWidgetData(widget.widgetId)?.data?.data.paidCount"
          [holdAmount]="getWidgetData(widget.widgetId)?.data?.data.holdAmount"
          [parkedAmount]="getWidgetData(widget.widgetId)?.data?.data.parkedAmount"
          [postedAmount]="getWidgetData(widget.widgetId)?.data?.data.postedAmount"
          [paidAmount]="getWidgetData(widget.widgetId)?.data?.data.paidAmount"
          (navigateDocument)="navigateToDocument($event,widget.name)">
        </tg-document-detail-count-widget>
      
        <ng-container *ngSwitchCase="'tg-finance-tat-report-widget'">
          <ng-container *ngIf="widgetData[widget.widgetId]">
            <tg-finance-tat-report-widget
              [centerText1]="widgetData[widget.widgetId]?.centerText1" 
              [centerText2]="widgetData[widget.widgetId]?.centerText2"
              [id]="'chart-container-' + widget.widgetId"
              [reportType]="widgetData[widget.widgetId]?.reportType"
              [data]="widgetData[widget.widgetId]?.data">
            </tg-finance-tat-report-widget>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="budegtDetails">
          <tg-budget-details-widget *ngSwitchCase="'tg-budget-details-widget'"
            [budegtDetails]="getWidgetData(widget.widgetId)?.data?.data" (nextPage)="getNextPage($event)"
            [lastUpdatedOn]="getWidgetData(widget.widgetId)?.data?.lastUpdatedAt" [pageSize]="pageSize"
            [totalRecords]="getWidgetData(widget.widgetId)?.data?.totalElements">
          </tg-budget-details-widget>
        </ng-container>

      </ng-container>
    </div>
  </ng-container>
</div>



<!-- old dashboard code starts-->

<!-- <div class="d-flex" *ngIf="viewModel?.employeeDetail">
  <div class="d-flex flex-column flex-grow-1 me-4">
    <div class="flex-between mb-4">
      <div class="d-flex flex-column">
        <div class="flex-v-center">
      <span
        class="lead-title">{{viewModel?.employeeDetail?.firstName}} {{viewModel?.employeeDetail?.lastName}}</span>
          <i class="bi bi-patch-check-fill ms-3 text-success section-title" pTooltip="Active"
             *ngIf="viewModel?.employeeDetail?.status === 'ACTIVE'"></i>
          <i class="bi bi-award-fill ms-3 text-primary" *ngIf="viewModel?.employeeDetail?.canBeDelegated" pTooltip="Manager"></i>
        </div>
        <span
          class="lead-byline">{{viewModel?.employeeDetail?.email}}</span>
      </div>

      <i class="bi bi-arrow-clockwise pointer" (click)="loadData()" pTooltip="Reload"></i>
    </div>
    <div class="d-flex flex-column">
      <div class="flex-between detail-section">
        <span class="tg-label">Employee Code</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.systemIdCode}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Vendor Code</span>
        <span *ngIf="viewModel?.employeeDetail?.glMainAccountCode != null else glAbsentWarning " class="tg-detail">{{viewModel?.employeeDetail?.glMainAccountCode}}</span>
        <ng-template #glAbsentWarning><span class="tg-detail" style="color:red; font-weight: bolder;">Not Present, please contact the Administrator</span></ng-template>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Gender</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.gender}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Date of Birth</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.dateOfBirth | date:'dd-MM-yyyy'}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Date of Joining</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.dateOfJoining | date:'dd-MM-yyyy'}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Grade and Type</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.grade}} {{viewModel?.employeeDetail?.type}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Branch</span>
        <span
          class="tg-detail">{{viewModel?.employeeDetail?.branchCode}} {{viewModel?.employeeDetail?.branchName ? ' | ' + viewModel?.employeeDetail?.branchName : ''}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Cost Center</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.costCentre}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Department</span>
        <span
          class="tg-detail">{{viewModel?.employeeDetail?.departmentCode}} {{viewModel?.employeeDetail?.departmentName ? ' | ' + viewModel?.employeeDetail?.departmentName : ''}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Profit Center</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.profitCentre}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Mobile Phone</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.mobileNumber}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Reporting Manager</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.manager}}</span>
      </div>
      <div class="flex-between detail-section">
        <span class="tg-label">Manager Details</span>
        <span class="tg-detail">{{viewModel?.employeeDetail?.managerEmployeeCode}} | {{viewModel?.employeeDetail?.managerEmail}}</span>
      </div>


    </div>
  </div>
  <div class="d-flex flex-column w-50 ms-4">
    <div class="d-flex flex-column mb-4">
      <span class="section-title mb-3">Activity: last 3 months</span>
      <p-chart type="bar" [data]="pastMonthsData" [options]="chartConfiguration" height="25vh"></p-chart>
    </div>

    <div class="d-flex flex-column my-2">
      <div class="flex-between-v-center">
        <span class="section-title">Statistics</span>
        <span class="tg-label">{{viewModel?.statistics?.startDate | date:'dd-MM-yyyy'}}
          to {{viewModel?.statistics?.endDate | date:'dd-MM-yyyy'}}</span>
      </div>

      <div class="flex-between mt-3">
        <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/queue/user', 'Paid']">
          <p-knob [ngModel]="viewModel?.statistics?.paidReports" [readonly]="true" [size]="100" [max]="getMax(viewModel?.statistics?.paidReports)"></p-knob>
          <span class="tg-label">Paid</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/queue/user', 'Accepted']">
          <p-knob [ngModel]="viewModel?.statistics?.acceptedReports" [readonly]="true" [size]="100" [max]="getMax(viewModel?.statistics?.acceptedReports)"></p-knob>
          <span class="tg-label">Accepted</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/queue/user', 'Submitted']">
          <p-knob [ngModel]="viewModel?.statistics?.submittedReports" [readonly]="true" [size]="100" [max]="getMax(viewModel?.statistics?.submittedReports)"></p-knob>
          <span class="tg-label">Submitted</span>
        </div>
      </div>
      <div class="flex-between mt-3">
        <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/queue/user', 'Sent Back']">
          <p-knob [ngModel]="viewModel?.statistics?.sentBackReports" [readonly]="true" [size]="100" [max]="getMax(viewModel?.statistics?.sentBackReports)"></p-knob>
          <span class="tg-label">Sent Back</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/queue/user', 'Discarded']">
          <p-knob [ngModel]="viewModel?.statistics?.discardedReports" [readonly]="true" [size]="100" [max]="getMax(viewModel?.statistics?.discardedReports)"></p-knob>
          <span class="tg-label">Discarded</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer" [routerLink]="['/queue/user', 'Draft']">
          <p-knob [ngModel]="viewModel?.statistics?.draftReports" [readonly]="true" [size]="100" [max]="getMax(viewModel?.statistics?.draftReports)"></p-knob>
          <span class="tg-label">Draft</span>
        </div>
      </div>
    </div>
  </div>
</div> -->