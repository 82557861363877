import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private dropdown1 = new BehaviorSubject<any>(null);
  dropdown1$ = this.dropdown1.asObservable();

  private dropdown2 = new BehaviorSubject<any>(null);
  dropdown2$ = this.dropdown2.asObservable();

  private dropdown3 = new BehaviorSubject<any>(null);
  dropdown3$ = this.dropdown3.asObservable();

  private dropdown4 = new BehaviorSubject<any>(null);
  dropdown4$ = this.dropdown4.asObservable();

  constructor() { }

  setDropdown1(value: any): void{
    this.dropdown1.next(value);
  }

  setDropdown2(value: any): void{
    this.dropdown2.next(value);
  }

  setDropdown3(value: any): void{
    this.dropdown3.next(value);
  }

  setDropdown4(value: any): void{
    this.dropdown4.next(value);
  }
}
