import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { DashboardService } from 'src/app/_services/_data-sharing/dashboard.service';

@Component({
  selector: 'tg-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss']
})
export class WidgetHeaderComponent implements OnInit {
  @Input() widgetTitle!: string;
  @Input() lastUpdatedDate!: string;
  @Input() isSwitchDropdown!: boolean;
  @Input() isTATByTitle!: boolean;
  @Input() dropdown1Data!: any;
  @Input() dropdown2Data!: any;

  @Input() dropdown3Data!: any;
  @Input() dropdown4Data!: any;

  @Input() widgetId: any;
  @Input() dropdown1Selection!: string;
  @Input() dropdown2Selection!: string;

  @Input() dropdown3Selection!: string;
  @Input() dropdown4Selection!: string;

  quickSearchValue!: string;
  @Output() quickSearchBlur = new EventEmitter<string>();

  @Output() dropdown1ChangedEmit = new EventEmitter<any>();
  @Output() dropdown2ChangedEmit = new EventEmitter<any>();

  @Output() dropdown3ChangedEmit = new EventEmitter<any>();
  @Output() dropdown4ChangedEmit = new EventEmitter<any>();

  @Output() exportClickEmit = new EventEmitter<boolean>();
  
  dropdown1: any;
  dropdown2: any;
  dropdown3: any;
  dropdown4: any;

  subscription1!: Subscription;
  subscription2!: Subscription;

  subscription3!: Subscription;
  subscription4!: Subscription;

  private destroy$ = new Subject<void>();

  constructor(private _dashboardDataSharingService: DashboardService) {}

  ngOnInit(): void {
    this.subscription1 = 
    this._dashboardDataSharingService.dropdown1$
    .pipe(takeUntil(this.destroy$))
    .subscribe(val=>{
      if(this.isSwitchDropdown){
        this.dropdown1 = val;
      }
    })

    this.subscription2 = 
    this._dashboardDataSharingService.dropdown2$
    .pipe(takeUntil(this.destroy$))
    .subscribe(val=>{
      if(this.isSwitchDropdown){
        this.dropdown2 = val;
      }
    })

    this.subscription3 = 
    this._dashboardDataSharingService.dropdown3$
    .pipe(takeUntil(this.destroy$))
    .subscribe(val=>{
      if(this.isTATByTitle){
        this.dropdown3 = val;
      }
    })

    this.subscription4 = 
    this._dashboardDataSharingService.dropdown4$
    .pipe(takeUntil(this.destroy$))
    .subscribe(val=>{
      if(this.isTATByTitle){
        this.dropdown4 = val;
      }
    })
  }

  onBlurEmitValue() {
    this.quickSearchBlur.emit(this.quickSearchValue); // Emit the quickSearchValue on blur
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscription4.unsubscribe();
  }

  dropdown1Changed(event: any){
    this._dashboardDataSharingService.setDropdown1(event);
    this.dropdown1ChangedEmit.emit(event);
  }
  
  dropdown2Changed(event: any){
    this._dashboardDataSharingService.setDropdown2(event);
    this.dropdown2ChangedEmit.emit(event);
  }

  dropdown3Changed(event: any){
    this._dashboardDataSharingService.setDropdown3(event);
    this.dropdown3ChangedEmit.emit(event);
  }
  
  dropdown4Changed(event: any){
    this._dashboardDataSharingService.setDropdown4(event);
    this.dropdown4ChangedEmit.emit(event);
  }

  emitExportClicked(){
    this.exportClickEmit.emit(true);
  }
}
