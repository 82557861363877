import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { OAuthModule } from 'angular-oauth2-oidc';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { DragDropModule } from 'primeng/dragdrop';
import { RippleModule } from 'primeng/ripple';
import { BaseInterceptorService } from './_services/base-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BadgeComponent } from './badge/badge.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LayoutModule } from './layout/layout.module';
import { LibModule } from './lib/lib.module';
import { MenuComponent } from './menu/menu.component';
import { NetworkRestrictionComponent } from './network-restriction/network-restriction.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PrimengModule } from './primeng/primeng.module';
import { StatCardContainerComponent } from './stat-card-container/stat-card-container.component';
import { StatCardComponent } from './stat-card/stat-card.component';
import { TableCellCardComponent } from './table-cell-card/table-cell-card.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { BudgetChangeConfirmationPopupComponent } from './common-components/budget-change-confirmation-popup/budget-change-confirmation-popup.component';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { DeleteConfirmationComponent } from './common-components/delete-confirmation/delete-confirmation.component';
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MenuComponent,
    PageHeaderComponent,
    StatCardComponent,
    StatCardContainerComponent,
    TableCellCardComponent,
    BadgeComponent,
    HomeComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    NetworkRestrictionComponent,
    BudgetChangeConfirmationPopupComponent,
    DeleteConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    LibModule,
    PrimengModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RippleModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['http://localhost:8085/api/v1/adfs-oauth'],
        sendAccessToken: true,
      },
    }),
    ToastrModule.forRoot({
      timeOut: 1000,
      maxOpened: 1,
    }),
    PopoverModule.forRoot(),
    TabsModule.forRoot(), DragDropModule,
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseInterceptorService,
      multi: true,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
