import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { ITATReportQueue } from 'src/app/_models/dashboards.models';
import { DashboardToInvoiceService } from 'src/app/_services/_data-sharing/dashboard-to-invoice.service';

interface CustomChart extends Highcharts.Chart {
  customText?: Highcharts.SVGElement;
  customTextGroup?: Highcharts.SVGElement; // Add custom property
}
@Component({
  selector: 'tg-finance-tat-report-widget',
  templateUrl: './finance-tat-report-widget.component.html',
  styleUrls: ['./finance-tat-report-widget.component.scss']
})
export class FinanceTatReportWidgetComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  @Input() centerText1!: string;
  @Input() centerText2!: string;
  @Input() id: any;
  @Input() data!: ITATReportQueue[];
  @Input() reportType!: string;

  constructor(private _dashboardToInvoice: DashboardToInvoiceService, 
    private _router: Router
  ) { }
  
  ngOnInit(): void {
  }

  getTotalInoiceAmount(): number{
    return this.data?.reduce((total, element: ITATReportQueue) => total + (element.invoiceAmount|| 0), 0) || 0;
  }

  getTotalInoices(): number{
    return this.data?.reduce((total, element: ITATReportQueue) => total + (element.y || 0), 0) || 0;
  }

  redirectToInovoice(aging: string){
    this._dashboardToInvoice.setAging(aging);
    this._dashboardToInvoice.setReportType(this.reportType);
    this._router.navigate(['/invoice']);
  }
}
