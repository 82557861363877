import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { ITATReportQueue } from 'src/app/_models/dashboards.models';
import { DashboardToInvoiceService } from 'src/app/_services/_data-sharing/dashboard-to-invoice.service';

interface CustomChart extends Highcharts.Chart {
  customText?: Highcharts.SVGElement;
  customTextGroup?: Highcharts.SVGElement; // Add custom property
}
@Component({
  selector: 'tg-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  @Input() centerText1!: string;
  @Input() centerText2!: string;
  @Input() id: any;
  @Input() data!: ITATReportQueue[];
  @Input() reportType!: string;

  private chart: CustomChart | null = null;

  constructor(private _dashboardToInvoice: DashboardToInvoiceService, 
    private _router: Router) { }

  ngAfterViewInit(): void {
    this.renderChart(this.centerText1, this.centerText2, this.data, this.id);
  }

  ngOnChanges(): void {
    if (this.chart) {
      this.renderChart(this.centerText1, this.centerText2, this.data, this.id); // Re-render the chart if inputs change
    }
  }

  ngOnDestroy(): void {
    // Destroy the chart to prevent memory leaks
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  private renderChart(centerText1: string, centerText2: string, data: any, id: any): void {
    const that = this;
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'pie',
        events: {
          render() {
            const chartInstance = this as CustomChart;
  
            // Remove previous text group if it exists
            if (chartInstance.customTextGroup) {
              chartInstance.customTextGroup.destroy();
            }
  
            // Calculate center coordinates
            const centerX = chartInstance.plotLeft + chartInstance.plotWidth / 2;
            const centerY = chartInstance.plotTop + chartInstance.plotHeight / 2;
  
            // Create a group to hold the text
            chartInstance.customTextGroup = chartInstance.renderer
              .g('custom-center-text')
              .attr({
                zIndex: 5, // Ensure it appears above the chart
              })
              .add();

            // const text1 = `<div>${centerText1}</div> <div style='color=#555'>${centerText2}</div>`
  
            // Add the first line of text
            chartInstance.renderer
              .text(centerText1, centerX, centerY - 15)
              .css({
                fontSize: '13px',
                color: '#333',
                fontWeight: 'bold',
                textAlign: 'center',
                width: 100
              })
              .attr({
                align: 'center',
              })
              .add(chartInstance.customTextGroup);
  
            // Add the second line of text
            chartInstance.renderer
              .text(centerText2, centerX, centerY + 25)
              .css({
                fontSize: '13px',
                color: '#555',
                textAlign: 'center',
              })
              .attr({
                align: 'center',
              })
              .add(chartInstance.customTextGroup);
          },
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          innerSize: '70%', // Makes it a doughnut
          dataLabels: {
            enabled: false,
          },
          point: {
            events: {
              click: function () {
                const point = this as Highcharts.Point & { invoiceAmount?: number | null}; // Extend Point type
                const { name, y, invoiceAmount } = point;
                that.myCustomFunction(name);
              }
            }
          }
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Share',
          data: [...data],
        },
      ],
      tooltip: {
        formatter: function () {
          const point = this as Highcharts.Point & { invoiceAmount: number }; // Extend Point type to include `amount`
          return `
            <b> Aging: </b> ${point['name']}</br>
            <b> No Of Invoices: </b> ${point['y']} </br>
            <b> Total Invoice Amount: </b> ${point['invoiceAmount']} </br>
          `
        }
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    }) as CustomChart; // Use the extended type
  }

  myCustomFunction(name: string){
    this._dashboardToInvoice.setReportType(this.reportType);
    this._dashboardToInvoice.setAging(name);
    this._router.navigate(['/invoice']);
  }

}
