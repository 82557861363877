import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './_guards/admin.guard';
import { AuthenticatedGuard } from './_guards/authenticated.guard';
import { HomeComponent } from './home/home.component';
import { NetworkRestrictionComponent } from "./network-restriction/network-restriction.component";
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'admin',
    canActivate: [AuthenticatedGuard, AdminGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'master',
    loadChildren: () => import('./components/masterdata/masterdata.module').then((m) => m.MasterdataModule)
  },
  {
    path: 'item',
    loadChildren: () => import('./components/masterdata/item-master/item-master.module').then((m) => m.ItemMasterModule)
  },
  {
    path: 'cem',
    canActivate: [AuthenticatedGuard, AdminGuard],
    loadChildren: () => import('./cem/cem.module').then((m) => m.CemModule),
  },
  {
    path: 'queue',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./queue/queue.module').then((m) => m.QueueModule),
  },
  {
    path: 'delegation',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./delegation/delegation.module').then((m) => m.DelegationModule),
  },
  {
    path: 'invoice',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./components/invoice/invoice.module').then((m) => m.InvoiceModule),
  },
  {
    path: 'purchase-requisition',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./components/purchase-requisition/purchase-requisition.module').then((m) => m.PurchaseRequisitionModule)
  },
  {
    path: 'purchase-order',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./components/purchase-order/purchase-order.module').then((m) => m.PurchaseOrderModule)
  },
  {
    path: 'budget',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./budget/budget.module').then((m) => m.BudgetModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./sign-up/sign-up.module').then((m) => m.SignUpModule)
  },
  {
    path: 'procurement',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./components/procurement/procurement.module').then((m) => m.ProcurementModule),
  },
  {
    path: 'user',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'entitlement',
    loadChildren: () => import('./entitlement/entitlement.module').then((m) => m.EntitlementModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-condition/terms-and-condition.module').then((m) => m.TermsAndConditionModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'network-restriction',
    component: NetworkRestrictionComponent,
  },
  {
    path: 'budget-drilldown',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('../app/common-components/budget-drilldown/budget-drilldown.module').then((m) => m.BudgetDrilldownModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./organisation-profile/organisation-profile.module').then((m) => m.OrganisationProfileModule),
  },
  {
    path: 'ocr',
    loadChildren: () => import('../app/components/ocr/ocr-template/ocr-template-routing.module').then(m => m.OCRTemplateRoutingModule)
  },
  {
    path: 'hierarchy',
    loadChildren: () => import('../app/components/label-hierarchy/label-hierarchy.module').then(m => m.LabelHierarchyModule)
  },
  {
    path: 'segment-ratio-master',
    loadChildren: () => import('./components/segment-ratio-master/segment-ratio-master.module').then((m) => m.SegmentRatioMasterModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
