import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { IInvoiceTATIdentifiersRequestViewModel, IInvoiceTATIdentifiersResponseViewModel } from 'src/app/_models/reports.models';
import { ServerResponseWithBody } from 'src/app/_models/app.models';
import { getUrlPathFragment } from 'src/app/_static/util';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardToInvoiceService {
  reportType!: string;
  aging!: string;
  constructor(private _restService: RestService) { }

  public getReportType(): string {
    return this.reportType;
  }

  public setReportType(reportType: string): void {
    this.reportType = reportType;
  }

  public getAging(): string {
    return this.aging;
  }

  public setAging(aging: string): void {
    this.aging = aging;
  }

  public getDocumentIdentifiersForReport(): Observable<string[]> {
    return this._restService.post<IInvoiceTATIdentifiersRequestViewModel, ServerResponseWithBody<IInvoiceTATIdentifiersResponseViewModel>>(
      getUrlPathFragment('dashboards', 'invoice-ageing-report', 'drilldown', 'items'),
      this.createInterval()
    )
    .pipe(
      map((res) => res.body),
      map((res) => res.documentIdentifiers)
    );
  }

  private createInterval(): IInvoiceTATIdentifiersRequestViewModel {
    if (this.aging) {
      // Remove the " Days" part and split the range
      const cleanedRange = this.aging.replace(' Days', '');
      console.log("AGING: " + this.aging);
      const [min, max] = cleanedRange.split('-').map(Number);
      return {
        minTat: min,
        maxTat: max,
        approverType: this.reportType
      }
    } else {
      return {} as IInvoiceTATIdentifiersRequestViewModel;
    }
  }

}
