import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, map, tap, Observable, switchMap } from 'rxjs';
import { ServerResponseWithBody } from 'src/app/_models/app.models';
import { IPDFModel } from 'src/app/_models/pdf.models';
import { PdfDownloadService } from 'src/app/_services/_shared_service/pdf-download.service';
import { RestService } from 'src/app/_services/rest.service';
import { getUrlPathFragment } from 'src/app/_static/util';
import { IPODetailsViewModelResponse } from 'src/app/components/purchase-order/models/purchase-order.model';

@Component({
  selector: 'tg-pdf-download',
  templateUrl: './pdf-download.component.html',
  styleUrls: ['./pdf-download.component.scss']
})
export class PdfDownloadComponent implements OnInit {
 
  @Input() id!: number;
  @Input() type!: string;
  @Input() title: string | null = null;
  @Input() documentStatus!: string;

  constructor(private _pdfService: PdfDownloadService) { }

  ngOnInit(): void {
  }

  downloadPdf() {
    this._pdfService.getPdfBase64(this.id, this.type).subscribe({
      next: (response: IPDFModel) => {
        const base64Data = response.pdfContent; // Extract Base64 from API response
        const fileName = (this.title ?? 'document') + ".pdf"; // Desired file name

        // Decode Base64 string and create Blob
        const binaryData = atob(base64Data);
        const byteNumbers = new Uint8Array(
          binaryData.split('').map((char) => char.charCodeAt(0))
        );
        const blob = new Blob([byteNumbers], { type: 'application/pdf' });

        // Create a link and trigger the download
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      },
      error: (err) => {
        console.error('Error fetching PDF:', err);
      },
    });
  }

}
